import React from 'react'
import { Seo } from '../../../components'
import { injectIntl, useIntl } from 'gatsby-plugin-intl'
import { PrivacyPolicyMobile } from '../../../sections'
import { FullScreen } from '../../../components/fullScreen'

const LightPrivacyPolicy = () => {
    const intl = useIntl()
    const t = x => {
        return intl.formatMessage({ id: x })
    }
    return (
        <FullScreen>
            <Seo
                title={t('privacy_policy_seo_title')}
                description={t('privacy_policy_seo_description')}
            />
            <PrivacyPolicyMobile locale={intl.locale}></PrivacyPolicyMobile>
        </FullScreen>
    )
}

export default injectIntl(LightPrivacyPolicy)
